import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

import { UqAppBarV2, UqLayoutV2, UqNavbarV2, UqFooterV2 } from '@uq-components';
import SEO from '../components/seo';

import * as styles from './legal.module.scss';

export default function LegalFreeTrialAgreementPage() {
  return (
    <UqLayoutV2>
      <UqAppBarV2
        className={styles.appbar}
        static
      >
        <UqNavbarV2 />
      </UqAppBarV2>

      <div className={styles.root}>
        <div className="master-container legal-free-trial-agreement-page">
          <SEO
            title="Legal Free Trial Agreement"
            description="Legal Free Trial Agreement"
          />
          <Container fluid className="px-0 main">
            <Row noGutters className="header-background justify-content-center">
              <Col className="justify-content-center">
              </Col>
            </Row>
            <Row noGutters className="no-header justify-content-center">
              <Col className="page-header-wrapper justify-content-center" />
            </Row>
            <Row className="mt-5 mb-4 d-flex justify-content-center">
              <Col className="layout-col align-center content-wrapper">
                <div className="legal-content">
                  <p className="c0 align-center">
                    <span className="c8">Free Trial Subscription Agreement</span>
                  </p>
                  <p className="c0">
                    <span className="c01">
                      This Free Trial Subscription Agreement (the “Agreement”)
                      contains Terms and Conditions that govern your acquisition of
                      subscription to the Service (as defined below) and is a contract
                      between unitQ, inc., a Delaware (USA) corporation (“unitQ”) and
                      you or the entity or organization that you represent.
                    </span>
                  </p>
                  <p className="c0">
                    <span className="c01">
                      If you are an individual using the Service for your own
                      purposes: (1) all references to <b>“Pilot Partner”</b> are to
                      you and (2) you represent and warrant that you are at least 18
                      years of age, or have otherwise reached the age of “majority”
                      where you reside, and that you have the right, power and
                      authority to enter into this Agreement.
                    </span>
                  </p>
                  <p className="c0">
                    <span className="c01">
                      If you are using the Service on behalf of an entity or
                      organization that you represent: (1) all references to{' '}
                      <b>“Pilot Partner”</b> are to that entity or organization and
                      (2) you represent and warrant that you are at least 18 years of
                      age, or have otherwise reached the age of “majority” where you
                      reside, and that you have the right, power and authority to
                      enter into this Agreement on behalf of Pilot Partner.
                    </span>
                  </p>
                  <p className="c0">
                    <span className="c01">
                      <b>
                        This Agreement becomes binding and effective on Pilot Partner
                        upon the earliest of: (1) when you access or use the Service
                        or (2) when you click an “I Accept,” “Sign up” or similar
                        button or check box referencing this Agreement.
                      </b>
                    </span>
                  </p>
                  <p className="c0">
                    <span className="c01">
                      <b>Service:</b> unitQ will provide Pilot Partner with access to
                      its service that identifies and categorizes product quality
                      issues through analysis of public and private data, as needed,
                      (the “Service”) and present that information to Pilot Partner on
                      a dashboard (the “Dashboard”).
                    </span>
                  </p>
                  <p className="c0">
                    <span className="c01">
                      <b>Data Access:</b> Pilot Partner shall provide unitQ with
                      access to agreed upon customer feedback channels.
                    </span>
                  </p>
                  <p className="c0">
                    <span className="c01">
                      <b>Review Language:</b> The Dashboard will be presented to Pilot
                      Partner in English.
                    </span>
                  </p>
                  <p className="c0">
                    <span className="c01">
                      <b>Data Retention Policy:</b> unitQ will not retain any
                      confidential information or personally identifiable information
                      of Pilot Partner and, in any event, will not retain such
                      information for longer than 30 days after the termination of
                      this Agreement.
                    </span>
                  </p>
                  <p className="c0">
                    <span className="c01">
                      <b>Evaluation Period:</b> The free trial is effective beginning
                      on the date you access or use the Service and ending thirty (30)
                      days thereafter (the “Evaluation Period”).
                    </span>
                  </p>
                  <p className="c0">
                    <span className="c01">
                      <b>Evaluation Period Limitations/Restrictions:</b> Pilot Partner
                      agrees that the Service is for Pilot Partner’s internal use
                      only.
                    </span>
                  </p>
                  <p className="pt-4 c0 align-center">
                    <span className="c8 text-underlined-title">
                      TERMS AND CONDITIONS
                    </span>
                  </p>
                  <p className="c0">
                    <span className="c01 d-flex flex-row">
                      <div className="legal-free-trial-agreement-left-column">
                        <b>1.</b>
                      </div>
                      <div>
                        <b>SERVICES AND SUPPORT</b>
                      </div>
                    </span>
                  </p>
                  <p className="c0">
                    <span className="c01 d-flex flex-row">
                      <div className="legal-free-trial-agreement-left-column">1.</div>
                      <div>
                        Subject to Pilot Partner’s compliance with the terms and
                        conditions of this Agreement, unitQ hereby agrees to provide
                        Pilot Partner the right to access and use the Service
                        specified in the Agreement. For clarity, the Service is hosted
                        by unitQ.
                      </div>
                    </span>
                  </p>
                  <p className="c0">
                    <span className="c01 d-flex flex-row">
                      <div className="legal-free-trial-agreement-left-column">2.</div>
                      <div>
                        unitQ grants Pilot Partner a nonexclusive, nontransferable,
                        non-sublicensable license, during the term of the Evaluation
                        Period, to use and access the Service. Except for the license
                        granted under this Section 1.2, unitQ and its licensors shall
                        retain all rights, title, and interest in and to the Service
                        and any software used or incorporated in the Service, and all
                        copies thereof. Notwithstanding the foregoing and upon
                        notification, unitQ reserves the right to suspend Pilot
                        Partner’s access to the Service: (i) for scheduled or
                        emergency maintenance, or (ii) in the event Pilot Partner is
                        in breach of this Agreement, and Pilot Partner has not cured
                        such breach within fifteen (15) days of receiving
                        notification.
                      </div>
                    </span>
                  </p>
                  <p className="c0">
                    <span className="c01 d-flex flex-row">
                      <div className="legal-free-trial-agreement-left-column">
                        <b>2.</b>
                      </div>
                      <div className="mb-1">
                        <b>RESTRICTIONS AND RESPONSIBILITIES</b>
                      </div>
                    </span>
                    <p className="c01 legal-free-trial-agreement-p-padding-left">
                      Pilot Partner will not, and will not knowingly permit any third
                      party to: reverse engineer, decompile, disassemble or otherwise
                      attempt to discover the source code, object code or underlying
                      structure, ideas or algorithms of the Service, documentation or
                      data related to the Service (provided that reverse engineering
                      is prohibited only to the extent such prohibition is not
                      contrary to applicable law); modify, translate, or create
                      derivative works based on the Service; use the Service for
                      timesharing or service bureau purposes or for any purpose other
                      than its own use for the benefit of Pilot Partner; or use the
                      Service other than in accordance with this Agreement and in
                      compliance with all applicable laws and regulations (including
                      but not limited to any European privacy laws, intellectual
                      property, consumer and child protection, obscenity or
                      defamation).
                    </p>
                  </p>
                  <p className="c0">
                    <span className="c01 d-flex flex-row">
                      <div className="legal-free-trial-agreement-left-column">
                        <b>3.</b>
                      </div>
                      <div>
                        <b>CONFIDENTIALITY</b>
                      </div>
                    </span>
                  </p>
                  <p className="c0">
                    <span className="c01 d-flex flex-row">
                      <div className="legal-free-trial-agreement-left-column">1.</div>
                      <div>
                        Each party (the “Receiving Party”) understands that the other
                        party (the “Disclosing Party”) has disclosed or may disclose
                        information relating to the Disclosing Party’s technology or
                        business or other information that would be considered
                        confidential by a reasonable person in the ordinary course of
                        business (hereinafter referred to as “Proprietary Information”
                        of the Disclosing Party).
                      </div>
                    </span>
                  </p>
                  <p className="c0">
                    <span className="c01 d-flex flex-row">
                      <div className="legal-free-trial-agreement-left-column">2.</div>
                      <div>
                        The Receiving Party agrees: (i) not to divulge to any third
                        person any such Proprietary Information, (i) to give access to
                        such Proprietary information solely to those Personnel with a
                        need to have access thereto for purposes of this Agreement,
                        and (iii) to take the same security precautions to protect
                        against disclosure or unauthorized use of such Proprietary
                        Information that the party takes with its own proprietary
                        information, but in no event will a party apply less than
                        reasonable precautions to protect such Proprietary
                        Information. The Disclosing Party agrees that the foregoing
                        will not apply with respect to any information that the
                        Receiving Party can document (a) is or becomes generally
                        available to the public without any action by, or involvement
                        of, the Receiving Party, or (b) was in its possession or known
                        by it prior to receipt from the Disclosing Party, or (c) was
                        rightfully disclosed to it without restriction by a third
                        party, or (d) was independently developed without use of any
                        Proprietary Information of the Disclosing Party. Nothing in
                        this Agreement will prevent the Receiving Party from
                        disclosing the Proprietary Information pursuant to any
                        judicial or governmental order, provided that the Receiving
                        Party gives the Disclosing Party reasonable prior notice of
                        such disclosure to contest such order. The foregoing shall not
                        restrict unitQ from collecting, using, and disclosing data
                        from Pilot Partner which has been aggregated and/or
                        de-identified in such a manner that neither Pilot Partner nor
                        any individual person can be specifically identified.
                      </div>
                    </span>
                  </p>
                  <p className="c0">
                    <span className="c01 d-flex flex-row">
                      <div className="legal-free-trial-agreement-left-column">3.</div>
                      <div>
                        Both parties will have the right to disclose the existence but
                        not the terms and conditions of this Agreement solely to those
                        with a need to know, or to those who have executed
                        non-disclosure agreements, unless such disclosure is approved
                        in writing by both parties prior to such disclosure, or is
                        included in a filing required to be made by a Party with a
                        governmental authority (provided such party will use
                        reasonable efforts to obtain confidential treatment or a
                        protective order) or is made on a confidential basis as
                        reasonably necessary to potential investors or acquirers.
                      </div>
                    </span>
                  </p>
                  <p className="c0">
                    <span className="c01 d-flex flex-row">
                      <div className="legal-free-trial-agreement-left-column mb-1">
                        4.
                      </div>
                      <div>
                        This Section 3 shall survive for a period of five (5) years
                        upon expiration or termination of this Agreement.
                      </div>
                    </span>
                  </p>
                  <p className="c0">
                    <span className="c01 d-flex flex-row">
                      <div className="legal-free-trial-agreement-left-column">
                        <b>4.</b>
                      </div>
                      <div className="mb-1">
                        <b>INTELLECTUAL PROPERTY RIGHTS</b>
                      </div>
                    </span>
                    <p className="c01 legal-free-trial-agreement-p-padding-left">
                      Except as expressly set forth herein, unitQ alone (and its
                      licensors, where applicable) will retain all intellectual
                      property rights relating to the Service or any suggestions,
                      ideas, enhancement requests, feedback, or recommendations
                      provided by Pilot Partner or any third party relating to the
                      Service, which are hereby assigned to unitQ. Pilot Partner will
                      not copy, distribute, reproduce or use any of the foregoing
                      except as expressly permitted under this Agreement. This
                      Agreement is not a sale and does not convey to Pilot Partner any
                      rights of ownership in or related to the Service, or any
                      intellectual property rights. unitQ shall indemnify, defend and
                      hold Pilot Partner harmless from liability to unaffiliated third
                      parties resulting from (i) breach by UnitQ of its
                      representations and warranties in this Agreement, and (ii) third
                      party claims of infringement by the Service of any patent or any
                      copyright or misappropriation of any trade secret, provided
                      unitQ is promptly notified of any claims and proceedings related
                      thereto (provided that any failure or delay in providing such
                      notice shall not relieve unitQ of its obligations under this
                      Section except to the extent such failure or delay materially
                      prejudices the defense of the claim) and given reasonable
                      assistance at UnitQ’s expense and the opportunity to assume sole
                      control over defense and settlement (provided that any
                      settlement that does not include a full release of Pilot Partner
                      will require Pilot Partner’s prior written consent). The
                      foregoing obligations do not apply to the extent portions or
                      components of the Service (i) resulting in whole or in part in
                      accordance from Pilot Partner specifications, (ii) are modified
                      by Pilot Partner after delivery by unitQ, (iii) are combined
                      with other products, processes or materials where the alleged
                      infringement relates to such combination, (iv) where Pilot
                      Partner continues to use the Service after being notified
                      thereof or after being informed of modifications that would have
                      avoided the alleged infringement, or (vi) where Pilot Partner’s
                      use of is in violation of this Agreement and all related
                      documentation. “Pilot Partner Content” is all content or
                      materials provided by or on behalf of Pilot Partner or its
                      Affiliates to unitQ hereunder, including (i) Pilot Partner Data,
                      (ii) any data, files, images or other content or materials
                      processed in connection with the Service, (iii) trademarks,
                      service marks, logos, insignia, trade names and other
                      designations of origin of Pilot Partner or its Affiliates, and
                      (iv) any other text, graphics, software, inventions, music, data
                      or other material in any form or media. Pilot Partner Content
                      will be deemed Confidential Information of Pilot Partner. For
                      the purposes of this Agreement, “Pilot Partner Data” shall mean
                      any data, content, statistics, usage information, and any other
                      material or information made available by or collected from
                      Pilot Partner, its Affiliates, other subcontractor or end users
                      in connection with the performance of the Service, including for
                      the avoidance of doubt any Personal Data.
                    </p>
                  </p>
                  <p className="c0">
                    <span className="c01 d-flex flex-row">
                      <div className="legal-free-trial-agreement-left-column">
                        <b>5.</b>
                      </div>
                      <div>
                        <b>TERMINATION</b>
                      </div>
                    </span>
                  </p>
                  <p className="c0">
                    <span className="c01 d-flex flex-row">
                      <div className="legal-free-trial-agreement-left-column">1.</div>
                      <div>
                        Subject to earlier termination as provided below, this
                        Agreement is for the Evaluation Period specified above.
                      </div>
                    </span>
                  </p>
                  <p className="c0">
                    <span className="c01 d-flex flex-row">
                      <div className="legal-free-trial-agreement-left-column">2.</div>
                      <div>
                        Either party may terminate this Agreement, without notice, via
                        written communication to the other party.{' '}
                      </div>
                    </span>
                  </p>
                  <p className="c0">
                    <span className="c01 d-flex flex-row">
                      <div className="legal-free-trial-agreement-left-column">3.</div>
                      <div>
                        All sections of this Agreement which by their nature should
                        survive termination will survive termination, including,
                        without limitation, indemnification, restrictions,
                        intellectual property rights, warranty disclaimers, and
                        limitations of liability. For clarity, upon expiration or
                        early termination, any personally identifiable information
                        included within Proprietary Information shall be destroyed by
                        the Receiving Party or returned to the Disclosing Party.
                      </div>
                    </span>
                  </p>
                  <p className="c0">
                    <span className="c01 d-flex flex-row">
                      <div className="legal-free-trial-agreement-left-column mb-1">
                        <b>6.</b>
                      </div>
                      <div>
                        <b>CLIENT SOFTWARE SECURITY</b>
                      </div>
                    </span>
                    <p className="c01 legal-free-trial-agreement-p-padding-left">
                      unitQ represents and warrants that it will not knowingly
                      include, in any unitQ software released to the public and
                      provided to Pilot Partner hereunder and will use commercially
                      reasonable methods to prevent, any computer code or other
                      computer instructions, devices or techniques, including without
                      limitation those known as disabling devices, trojans, viruses,
                      malware, adware, spyware, or time bombs, that intentionally
                      disrupt, disable, harm, infect, defraud, damage, or otherwise
                      impede in any manner, the operation of a network, computer
                      program or computer system or any component thereof, including
                      its security or user data. If, at any time, unitQ fails to
                      comply with the warranty in this Section, Pilot Partner may
                      promptly notify unitQ in writing of any such noncompliance.
                      unitQ will, within thirty (30) days of receipt of such written
                      notification, either correct the noncompliance or provide Pilot
                      Partner with a plan for correcting the noncompliance. If the
                      noncompliance is not corrected or if a reasonably acceptable
                      plan for correcting them is not established during such period,
                      Pilot Partner may terminate this Agreement and unitQ shall issue
                      Pilot Partner a refund for any prepaid, unused (calculated on a
                      pro-rated basis) Fees. unitQ further represents, warrants and
                      covenants to Pilot Partner that: (a) the Service, and Pilot
                      Partner’s use thereof as contemplated, do not and will not
                      infringe the intellectual property rights of any person or
                      entity; (b) the Service does not contain any malicious code, and
                      (c) in providing the Services, unitQ will comply with all
                      applicable laws and with highest industry standards for data
                      privacy and security as well as the Data Processing Addendum
                      attached hereto as Exhibit A.
                    </p>
                  </p>
                  <p className="c0">
                    <span className="c01 d-flex flex-row">
                      <div className="legal-free-trial-agreement-left-column mb-1">
                        <b>7.</b>
                      </div>
                      <div>
                        <b>DISCLAIMER; LIMITATION OF LIABILITY</b>
                      </div>
                    </span>
                    <p className="c01 legal-free-trial-agreement-p-padding-left">
                      EXCEPT AS EXPRESSLY STATED HEREIN, THE SERVICES ARE PROVIDED AS
                      IS AND ALL EXPRESS AND IMPLIED WARRANTIES (INCLUDING
                      MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE) ARE HEREBY
                      DISCLAIMED. EXCEPT FOR THE INDEMNIFICATION OBLIGATIONS HEREIN OR
                      BREACH OF THE CONFIDENTIALITY PROVISIONS HEREIN, IN NO EVENT
                      WILL UNITQ OR PILOT PARTNER (OR ANY OF ITS AGENTS, AFFILIATES,
                      LICENSORS OR SUPPLIERS) BE LIABLE FOR ANY INDIRECT, PUNITIVE,
                      INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES, OR COST OF
                      PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY, ARISING
                      OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF THE SERVICE OR
                      ANYTHING PROVIDED IN CONNECTION WITH THIS AGREEMENT, THE DELAY
                      OR INABILITY TO USE THE SERVICE OR ANYTHING PROVIDED IN
                      CONNECTION WITH THIS AGREEMENT OR OTHERWISE ARISING FROM THIS
                      AGREEMENT WHETHER BASED IN CONTRACT, TORT (INCLUDING
                      NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE, EVEN IF UNITQ OR
                      PILOT PARTNER HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.
                      EXCEPT FOR THE INDEMNIFICATION OBLIGATIONS HEREIN OR BREACH OF
                      THE CONFIDENTIALITY PROVISIONS HEREIN, THE TOTAL LIABILITY OF
                      UNITQ AND PILOT PARTNER, WHETHER BASED IN CONTRACT, TORT
                      (INCLUDING NEGLIGENCE OR STRICT LIABILITY), OR OTHERWISE, WILL
                      NOT EXCEED, IN THE AGGREGATE, THE GREATER OF (i) TEN THOUSAND
                      DOLLARS, OR (ii) THE FEES PAID TO UNITQ HEREUNDER. THE
                      LIMITATIONS IN THIS SECTION 7 WILL APPLY NOTWITHSTANDING ANY
                      FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY, BUT SHALL
                      NOT APPLY TO ANY BREACH OF SECTIONS 2, 4 OR 6, OR TO DAMAGES
                      CAUSED BY FRAUD OR GROSS NEGLIGENCE.
                    </p>
                  </p>
                  <p className="c0">
                    <span className="c01 d-flex flex-row">
                      <div className="legal-free-trial-agreement-left-column mb-1">
                        <b>8.</b>
                      </div>
                      <div>
                        <b>MISCELLANEOUS</b>
                      </div>
                    </span>
                    <p className="c01 legal-free-trial-agreement-p-padding-left">
                      If any provision of this Agreement is found to be unenforceable
                      or invalid, that provision will be limited or eliminated to the
                      minimum extent necessary so that this Agreement will otherwise
                      remain in full force and effect and enforceable. This Agreement
                      is not assignable, transferable or sublicensable by Pilot
                      Partner except with unitQ’s prior written consent, which consent
                      shall not be unreasonably withheld or delayed. Both parties
                      agree that this Agreement is the complete and exclusive
                      statement of the mutual understanding of the parties and
                      supersedes and cancels all previous written and oral agreements,
                      communications and other understandings relating to the subject
                      matter of this Agreement, and that all waivers and modifications
                      must be in a writing signed by both parties, except as otherwise
                      provided herein. No agency, partnership, joint venture, or
                      employment is created as a result of this Agreement and neither
                      party has any authority of any kind to bind the other party in
                      any respect whatsoever. In any action or proceeding to enforce
                      rights under this Agreement, the prevailing party will be
                      entitled to recover reasonable costs and attorneys’ fees. All
                      notices under this Agreement will be in writing and will be
                      deemed to have been duly given when received, if personally
                      delivered; when receipt is electronically confirmed, if
                      transmitted by facsimile or e-mail; and upon receipt, if sent by
                      certified or registered mail (return receipt requested), postage
                      prepaid. Neither party will be liable for any loss resulting
                      from a cause over which it does not have direct control. This
                      Agreement will be governed by the laws of the State of
                      California, U.S.A. without regard to its conflict of laws
                      provisions. The federal and state courts sitting in San
                      Francisco, California, U.S.A. will have proper and exclusive
                      jurisdiction and venue with respect to any disputes arising from
                      or related to the subject matter of this Agreement, provided
                      that either party may seek injunctive relief in any court of
                      competent jurisdiction.
                    </p>
                  </p>
                  <p className="c12">
                    <span className="c4" />
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <UqFooterV2 />
    </UqLayoutV2>
  );
}
